import Link from 'next/link';

import { Logo } from 'components/icons/Logo';
import { isCrushAppMobile } from 'utils/userAgentCheck';

const columns = [
  {
    name: 'Features',
    links: [
      { title: 'Chat', new_tab: false, href: '/chat' },
      { title: 'Create your Crush', new_tab: false, href: '/create' },
      { title: 'Browse Categories', new_tab: true, href: '/categories' }
    ]
  },
  {
    name: 'Company',
    links: [
      { title: 'About Crush', new_tab: true, href: '/about' },
      { title: 'FAQ', new_tab: true, href: '/faq' },
      { title: 'Blog', new_tab: true, href: 'https://blog.crush.to/' },
      { title: 'Become an Affiliate', new_tab: true, href: '/affiliate-program' }
    ]
  },
  {
    name: 'Legal',
    links: [
      { title: 'Terms of Service', new_tab: true, href: '/terms' },
      { title: 'Privacy Policy', new_tab: true, href: '/privacy' },
      { title: 'Content Removal', new_tab: true, href: '/dmca' }
    ]
  },
  {
    name: '',
    links: []
  }
];

export const Footer = () =>
  isCrushAppMobile() ? null : (
    <footer className="py-8">
      <div className="container">
        <div className="flex flex-col gap-10">
          <div className="flex flex-col lg:flex-row gap-10 lg:gap-28 xl:gap-32">
            <div className="flex flex-col gap-4">
              <Link prefetch={false} href="/" className="w-fit">
                <Logo />
              </Link>
              <p className="w-[350px] text-base">
                Experience virtual companions that feel alive. Chat with the best AI Girlfriends and Chatbots for flirt,
                fling, or roleplay.
              </p>
              <p className="text-gray-500">&copy; 2024 Crush.to. All rights reserved.</p>
            </div>
            <div className="flex-1">
              <div className="grid grid-cols-2 md:flex flex-row items-start justify-between gap-8 md:gap-4">
                {columns.map((column) => (
                  <div key={column.name}>
                    <h4 className="font-semibold text-lg md:text-xl mb-3 text-gray-300">{column.name}</h4>
                    <ul className="flex flex-col gap-2">
                      {column.links.map((link) => (
                        <li key={link.title}>
                          <Link
                            prefetch={false}
                            href={link.href}
                            className="hover:text-brand text-sm md:text-base"
                            target={link.new_tab ? '_blank' : undefined}
                            rel={link.new_tab ? 'noopener noreferrer' : undefined}
                          >
                            {link.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              <div className="pt-2">
                <a
                  href="https://theresanaiforthat.com/ai/crush-to/?ref=featured&v=1225596"
                  target="_blank"
                  rel="nofollow"
                >
                  <img
                    width="200"
                    alt={`There's an AI for that banner`}
                    src="https://media.theresanaiforthat.com/featured-on-taaft.png?width=600"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
